import { useMap } from '@vis.gl/react-google-maps';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { MarkerClusterer as GoogleMarkerClusterer } from '@googlemaps/markerclusterer';
import PropTypes from 'prop-types';
import ClusteredMarker from '../ClusteredMarker';
import googleMapsStyles from '../../googleMapsStyles';
import { MAP_ID } from '../../GoogleMaps.consts';
import { MapPointShape } from '../../GoogleMaps.shapes';
import { getClusterDiv } from './MarkerClusterer.utils';

export const MarkerClusterer = ({
  markersData,
  theme,
  onMarkerClick,
  isMapWithClusters,
  checkedInvestments,
  hideTooltipIfUnchecked,
  isOldMarkerStyle,
  isModalOpen,
  activeMarkedEntityData,
  handleMarkedEntityInfoClose,
  defaultCenter,
  defaultZoom,
}) => {
  const [markers, setMarkers] = useState({});

  const map = useMap();
  const clusterer = useMemo(() => {
    if (!map) return null;

    let renderer = null;

    if (window && window.google) {
      const styledMapType = new window.google.maps.StyledMapType(googleMapsStyles(theme));
      map.mapTypes.set(MAP_ID, styledMapType);
      map.setMapTypeId(MAP_ID);

      renderer = {
        render({ count, position }) {
          return new window.google.maps.marker.AdvancedMarkerElement({
            map,
            position,
            content: getClusterDiv(count),
          });
        },
      };
    }

    if (!isMapWithClusters) return null;

    return new GoogleMarkerClusterer({ map, markers: [], renderer });
  }, [map, activeMarkedEntityData]);

  useEffect(() => {
    const defaultZoomButtons = document.querySelectorAll('#defaultZoomButton');

    const handleDefaultZoomButtonClick = () => {
      map.setZoom(defaultZoom);
      map.setCenter(defaultCenter);
      handleMarkedEntityInfoClose();
    };

    if (defaultZoomButtons && defaultZoomButtons.length) {
      defaultZoomButtons.forEach((defaultZoomButton) => {
        defaultZoomButton.addEventListener('click', handleDefaultZoomButtonClick);
      });
    }

    if (!clusterer) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
    clusterer.addListener('click', handleMarkedEntityInfoClose);
  }, [clusterer, markers]);

  const setMarkerRef = useCallback((marker, key) => {
    setMarkers((currentMarkers) => {
      if ((marker && currentMarkers[key]) || (!marker && !currentMarkers[key])) {
        return currentMarkers;
      }

      if (marker) {
        return { ...currentMarkers, [key]: marker };
      }
      const { [key]: _, ...newMarkers } = currentMarkers;

      return newMarkers;
    });
  }, []);

  return (
    <>
      {markersData.map((markerData, key) => (
        <ClusteredMarker
          key={`${markerData.slug}-${key}`}
          markerData={markerData}
          onClick={onMarkerClick}
          setMarkerRef={setMarkerRef}
          hideTooltipIfUnchecked={hideTooltipIfUnchecked}
          checkedInvestments={checkedInvestments}
          isOldMarkerStyle={isOldMarkerStyle}
          isModalOpen={isModalOpen}
          activeMarkedEntityData={activeMarkedEntityData}
        />
      ))}
    </>
  );
};

MarkerClusterer.propTypes = {
  markersData: PropTypes.arrayOf(MapPointShape.isRequired).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  activeMarkedEntityData: PropTypes.object,
  checkedInvestments: PropTypes.arrayOf(PropTypes.string),
  defaultCenter: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
  defaultZoom: PropTypes.number,
  handleMarkedEntityInfoClose: PropTypes.func,
  hideTooltipIfUnchecked: PropTypes.bool,
  isMapWithClusters: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  isOldMarkerStyle: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object,
  onMarkerClick: PropTypes.func,
};

MarkerClusterer.defaultProps = {
  isMapWithClusters: false,
  onMarkerClick: () => {},
  points: [],
  zoom: null,
  hideTooltipIfUnchecked: false,
  isOldMarkerStyle: false,
  checkedInvestments: [],
  isModalOpen: false,
  activeMarkedEntityData: null,
  handleMarkedEntityInfoClose: () => {},
  defaultCenter: null,
  defaultZoom: null,
};
