const breakpoints = {
  xs: '375px',
  mb: '475px',
  sm: '767px',
  md: '992px',
  lg: '1200px',
  alg: '1280px',
  xl: '1400px',
  xxl: '1440px',
  og: '1920px',
};

export default breakpoints;
