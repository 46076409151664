import React from 'react';
import PropTypes from 'prop-types';
import { VisibilityContainer } from 'utils/styles';

import {
  Wrapper, BoldText, LightText, RegularText, TopHeader, SuperLightText,
} from './SectionHeader.styles';

const SectionHeader = ({
  titleRef,
  black,
  bold,
  boxPosition,
  children,
  light,
  lightTextColor,
  longBox,
  narrowBox,
  size,
  uppercase,
  alignCenter,
  boldText,
  lightText,
  regularText,
  superLightText,
  topHeaderDescriptionText,
  alignTextLeft,
  greyTextColor,
  shortUnderline,
}) => (
  <Wrapper
    greyTextColor={greyTextColor}
    boxPosition={boxPosition}
    uppercase={uppercase}
    size={size}
    longBox={longBox}
    narrowBox={narrowBox}
    bold={bold}
    black={black}
    light={light}
    lightTextColor={lightTextColor}
    alignCenter={alignCenter}
    alignTextLeft={alignTextLeft}
    shortUnderline={shortUnderline}
  >
    {topHeaderDescriptionText && (
    <VisibilityContainer md lg>
      <TopHeader>
        {topHeaderDescriptionText}
      </TopHeader>
    </VisibilityContainer>
    )}
    {boldText && <BoldText innerRef={titleRef}>{boldText}&nbsp;</BoldText>}
    {regularText && <RegularText innerRef={titleRef}>{regularText}</RegularText>}
    {lightText && <LightText innerRef={titleRef}>{lightText}</LightText>}
    {superLightText && <SuperLightText innerRef={titleRef}>{superLightText}</SuperLightText>}
    {children}
  </Wrapper>
);

SectionHeader.propTypes = {
  alignCenter: PropTypes.bool,
  alignTextLeft: PropTypes.bool,
  black: PropTypes.bool,
  bold: PropTypes.bool,
  boldText: PropTypes.string,
  boxPosition: PropTypes.oneOf(['left', 'bottom', 'bottom-left', 'none']),
  children: PropTypes.node,
  greyTextColor: PropTypes.bool,
  light: PropTypes.bool,
  lightText: PropTypes.string,
  lightTextColor: PropTypes.bool,
  longBox: PropTypes.bool,
  narrowBox: PropTypes.bool,
  regularText: PropTypes.string,
  shortUnderline: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge', 'moderate']),
  superLightText: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  titleRef: PropTypes.object,
  topHeaderDescriptionText: PropTypes.string,
  uppercase: PropTypes.bool,
};

SectionHeader.defaultProps = {
  bold: false,
  black: false,
  boxPosition: 'left',
  light: false,
  lightTextColor: false,
  longBox: false,
  narrowBox: false,
  size: 'small',
  uppercase: true,
  alignCenter: false,
  boldText: '',
  lightText: '',
  superLightText: '',
  topHeaderDescriptionText: '',
  alignTextLeft: false,
  children: null,
  greyTextColor: false,
  titleRef: null,
  regularText: '',
  shortUnderline: false,
};

export default SectionHeader;
