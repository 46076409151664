export const getClusterDiv = (count) => {
  const imgGrayPath = 'static/svg/markerClusterDarkGray.svg';

  const cluster = document.createElement('div');
  cluster.style.cssText = `
    background-image: url('${imgGrayPath}');
    width: 40px;
    height: 40px;
    font-family: Roboto;
    font-weight: 800;
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 8px;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  cluster.innerHTML = count;

  return cluster;
};
