import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';
import { withContentRect } from 'react-measure';
import Modal from 'components/ui/Modal';
import {
  Map as GoogleMap, APIProvider,
} from '@vis.gl/react-google-maps';
import { fitBounds } from 'google-map-react/utils';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import some from 'lodash/some';
import config from 'config';

import withSizes from 'utils/withSizes';
import { Router } from 'Router';
import { MapPointShape } from './GoogleMaps.shapes';
import {
  Wrapper, StaticWrapper, Empty, DefaultZoomButton,
} from './GoogleMaps.styles';
import messages from './GoogleMaps.messages';
import googleMapsStyles from './googleMapsStyles';
import ClusteredMarkers from './components/MarkerClusterer';
import MapInfoOverlay from './components/MapInfoOverlay';
import { MAP_ID } from './GoogleMaps.consts';

const MAX_ZOOM = 17;
const DEFAULT_ZOOM = 11;
const DEFAULT_ZOOM_SINGLE = 17;

const findBoundsPoint = (points) => {
  const lats = points.map((el) => el.lat);
  const lngs = points.map((el) => el.lng);

  const minLat = Math.min(...lats);
  const maxLat = Math.max(...lats);
  const minLng = Math.min(...lngs);
  const maxLng = Math.max(...lngs);

  return {
    nw: {
      lat: maxLat,
      lng: minLng,
    },
    se: {
      lat: minLat,
      lng: maxLng,
    },
  };
};

class GoogleMaps extends React.Component {
  static propTypes = {
    contentRect: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    measureRef: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    center: MapPointShape,
    height: PropTypes.number,
    hideTooltipIfUnchecked: PropTypes.bool,
    isMapWithClusters: PropTypes.bool,
    isMapWithMobileModal: PropTypes.bool,
    isMobileMapBigger: PropTypes.bool,
    isOldMarkerStyle: PropTypes.bool,
    mapKey: PropTypes.string,
    points: PropTypes.arrayOf(MapPointShape),
    staticMapUrl: PropTypes.string,
    zoom: PropTypes.number,
  };

  static defaultProps = {
    points: [],
    center: null,
    height: null,
    mapKey: 'mapKey',
    staticMapUrl: null,
    zoom: null,
    hideTooltipIfUnchecked: false,
    isOldMarkerStyle: false,
    isMapWithClusters: false,
    isMobileMapBigger: false,
    isMapWithMobileModal: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      renderStatic: props.staticMapUrl !== null,
      modalOpen: false,
      isMobile: props.screenType === 'xs',
      isMarkedEntityInfoShown: false,
      activeMarkedEntityData: null,
    };
    this.cameraState = React.createRef();
    this.scrollPositionBeforeModalOpen = React.createRef();
    this.cameraState.current = {};
    this.scrollPositionBeforeModalOpen.current = { x: 0, y: 0 };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return ((this.state.renderStatic !== nextState.renderStatic)
      || (this.props.center !== nextProps.center)
      || (this.props.height !== nextProps.height)
      || (this.props.zoom !== nextProps.zoom)
      || (isEqual(this.props.contentRect.bounds, nextProps.contentRect.bounds))
      || (isEqual(this.props.points, nextProps.points)));
  }

  changeToDynamicMap = () => this.setState({ renderStatic: false });

  isEmptyPoints = (points) => {
    if (isEmpty(this.props.points)) {
      return true;
    }

    return !some(points, (el) => el.lat && el.lng);
  }

  handleModalClose = (returnToPreviousScrollPosition) => {
    this.setState({
      modalOpen: false,
      isMarkedEntityInfoShown: false,
      activeMarkedEntityData: null,
    }, () => {
      if (returnToPreviousScrollPosition) {
        window.scrollTo(
          this.scrollPositionBeforeModalOpen.current.x,
          this.scrollPositionBeforeModalOpen.current.y,
        );
      }
    });
  };

  handleModalOpen = () => {
    this.scrollPositionBeforeModalOpen.current = { x: window.scrollX, y: window.scrollY };
    this.setState({ modalOpen: true });
  };

  handleMarkedEntityInfoClose = () => {
    this.setState({ isMarkedEntityInfoShown: false, activeMarkedEntityData: null });
  };

  handleMarkerClick = ({
    id, slug, type, route, externalURL, name, markerData,
  }) => {
    if (this.props.isMapWithMobileModal && this.state.isMobile && !this.state.modalOpen) {
      this.handleModalOpen();
      this.setState({ activeMarkedEntityData: markerData.entityData });
      this.setState({ isMarkedEntityInfoShown: true });
    } else if (this.props.isMapWithMobileModal && this.state.isMobile && this.state.modalOpen) {
      this.setState({ activeMarkedEntityData: markerData.entityData });
      this.setState({ isMarkedEntityInfoShown: true });
    } else {
      this.props.onMarkerClick({
        id, slug, type, route, externalURL, name,
      });
    }
  };

  handleShowInvestmentButtonClick = () => {
    this.handleModalClose();

    const elementToScroll = document.getElementById(this.state.activeMarkedEntityData.slug);

    if (elementToScroll) {
      elementToScroll.scrollIntoView({ behavior: 'smooth' });
    } else if (this.state.activeMarkedEntityData.externalURL) {
      window.open(this.state.activeMarkedEntityData.externalURL, '_blank');
    } else {
      Router.pushRoute('investmentDescriptionDetails', { id: this.state.activeMarkedEntityData.investmentSlug });
    }
  };

  handleCameraChange = (ev) => {
    this.cameraState.current = ev.detail;
  };

  render() {
    let content = null;
    let map = () => {};

    if (this.isEmptyPoints(this.props.points)) {
      content = (
        <Empty>
          <FormattedMessage {...messages.empty} />
        </Empty>
      );
    } else if (isEmpty(this.props.contentRect.bounds) || this.state.renderStatic) {
      content = (
        <StaticWrapper onClick={this.changeToDynamicMap} mapUrl={this.props.staticMapUrl} />
      );
    } else {
      const size = {
        // 20 is for padding
        width: this.props.contentRect.bounds.width - 20,
        height: this.props.contentRect.bounds.height - 20,
      };

      const uniqPoints = uniqWith(this.props.points, isEqual);

      let calculatedBounds = {
        center: {
          lat: parseFloat(uniqPoints[0].lat),
          lng: parseFloat(uniqPoints[0].lng),
        },
        zoom: DEFAULT_ZOOM_SINGLE,
      };

      if (uniqPoints.length > 1) {
        calculatedBounds = fitBounds(findBoundsPoint(uniqPoints), size);
      }

      const finalCenter = this.props.center || calculatedBounds.center;

      let finalZoom = this.props.zoom || calculatedBounds.zoom || DEFAULT_ZOOM;

      if (finalZoom > MAX_ZOOM) {
        finalZoom = MAX_ZOOM;
      }

      const newUniqPoints = uniqPoints.map((point) => ({
        ...point,
        position: {
          lat: parseFloat(point.lat),
          lng: parseFloat(point.lng),
        },
      }));

      const getCheckedInvestmentsList = () => {
        if (this.props.checkedInvestments && this.props.checkedInvestments.length) {
          return this.props.checkedInvestments;
        } if (this.state.activeMarkedEntityData && this.state.activeMarkedEntityData.id) {
          return [this.state.activeMarkedEntityData.id];
        }

        return [];
      };

      map = () => {
        if (content) {
          return content;
        }

        return (
          // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
          <GoogleMap
            mapId={MAP_ID}
            key={this.props.mapKey}
            defaultCenter={this.cameraState.current.center || finalCenter}
            defaultZoom={this.cameraState.current.zoom || finalZoom}
            styles={googleMapsStyles(this.props.theme)}
            disableDefaultUI
            onCameraChanged={this.handleCameraChange}
            options={{
              maxZoom: MAX_ZOOM,
            }}
          >
            <DefaultZoomButton
              activeMarkedEntityData={this.state.activeMarkedEntityData}
              isModalOpen={this.state.modalOpen}
              id="defaultZoomButton"
            >1:1
            </DefaultZoomButton>
            <ClusteredMarkers
              markersData={newUniqPoints}
              theme={this.props.theme}
              onMarkerClick={this.handleMarkerClick}
              isMapWithClusters={this.props.isMapWithClusters}
              checkedInvestments={getCheckedInvestmentsList()}
              hideTooltipIfUnchecked={this.props.hideTooltipIfUnchecked}
              isOldMarkerStyle={this.props.isOldMarkerStyle}
              isModalOpen={this.state.modalOpen}
              activeMarkedEntityData={this.state.activeMarkedEntityData}
              handleMarkedEntityInfoClose={this.handleMarkedEntityInfoClose}
              defaultCenter={finalCenter}
              defaultZoom={finalZoom}
            />
          </GoogleMap>
        );
      };
    }


    return (
      <APIProvider apiKey={config.google.mapKey}>
        {!this.state.modalOpen && (
        <Wrapper
          innerRef={this.props.measureRef}
          mapHeight={this.props.height}
          style={{ display: 'flex', flexDirection: 'row' }}
          isMobileMapBigger={this.props.isMobileMapBigger}
        >
          {content || map()}
        </Wrapper>)}
        {this.props.isMapWithMobileModal && (
          <Modal
            open={this.state.modalOpen}
            onModalClose={() => this.handleModalClose(true)}
            isMobileFullScreen
          >
            <Wrapper isModal isMobileMapBigger={this.props.isMobileMapBigger}>
              {content || map()}
              <MapInfoOverlay
                isMarkedEntityInfoShown={
                this.state.modalOpen
                && this.state.activeMarkedEntityData
                 && this.state.activeMarkedEntityData.id
                  && this.state.isMarkedEntityInfoShown
              }
                activeMarkedEntityData={this.state.activeMarkedEntityData}
                handleMarkedEntityInfoClose={this.handleMarkedEntityInfoClose}
                handleShowInvestmentButtonClick={this.handleShowInvestmentButtonClick}
              />
            </Wrapper>
          </Modal>
        )}
      </APIProvider>
    );
  }
}

export default withSizes()(withTheme(withContentRect('bounds')(GoogleMaps)));
