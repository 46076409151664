import React from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';

import SectionHeader from 'components/ui/SectionHeader';
import ContactUsEntry from './components/ContactUsEntry';

import { ContactUsEntryShape } from './ContactUsBlock.shapes';
import {
  ItemsWrapper,
  ItemWrapper,
  Wrapper,
} from './ContactUsBlock.styles';

const ContactUsBlock = ({
  items, showIcons, title, transparentBackground, alignCenter, rowLayout,
}) => (
  <Wrapper transparentBackground={transparentBackground} alignCenter={alignCenter}>
    {isString(title) ? (
      <SectionHeader longBox uppercase={false} boxPosition="bottom" size="large" lightText={title} alignTextLeft />
    ) : title}
    <ItemsWrapper rowLayout={rowLayout}>
      {
        items.filter(({ email, phone, open }) => email || phone || open).map((el, key) => (
          <ItemWrapper key={key}>
            <ContactUsEntry {...el} showIcons={showIcons} />
          </ItemWrapper>
        ))
      }
    </ItemsWrapper>
  </Wrapper>
);

ContactUsBlock.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  alignCenter: PropTypes.bool,
  items: PropTypes.arrayOf(ContactUsEntryShape),
  rowLayout: PropTypes.bool,
  showIcons: PropTypes.bool,
  transparentBackground: PropTypes.bool,
};

ContactUsBlock.defaultProps = {
  items: [],
  showIcons: false,
  transparentBackground: false,
  alignCenter: false,
  rowLayout: false,
};

export default ContactUsBlock;
