import React from 'react';
import PropTypes from 'prop-types';

import { StyledButton } from './Close.styles';

const Close = ({ onClick, isMobileFullScreen }) => (
  <StyledButton onClick={onClick} isMobileFullScreen={isMobileFullScreen} />
);

Close.propTypes = {
  onClick: PropTypes.func.isRequired,
  isMobileFullScreen: PropTypes.bool,
};

Close.defaultProps = {
  isMobileFullScreen: false,
};

export default Close;
