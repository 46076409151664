import React from 'react';
import PropTypes from 'prop-types';

import SlideArrowIcon from 'components/icons/SlideArrow';
import { Button } from './Arrow.styles';

const Arrow = ({ direction, onClick, isDisabled }) => (
  <Button direction={direction} onClick={onClick} disabled={isDisabled}>
    <SlideArrowIcon height="14px" />
  </Button>
);

Arrow.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Arrow.defaultProps = {
  onClick: () => {},
  isDisabled: false,
};

export default Arrow;
