import styled, { css } from 'styled-components';

import leftArrow from './img/leftArrow.png';
import rightArrow from './img/rightArrow.png';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  max-height: ${(props) => (props.isStaticHeight ? '85dvh' : 'fit-content')};
  padding: 32px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-size: 16px;
  box-sizing: border-box;

  @media (max-width: ${(props) => props.theme.breakpoints.xxl}) {
    padding: 24px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.alg}) {
    padding: 20px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    padding: 16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
    height: ${(props) => (props.isMobileFullScreen ? '100%' : 'unset')};
    padding: ${(props) => (props.isMobileFullScreen ? '0' : '16px')};
  }

  ${(props) => props.small && css`
    padding: 0;
  `}
`;

const CloseWrapper = styled.div`
  position: absolute;
  top: -22px;
  right: -22px;
  z-index: 20;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    top: -16px;
    right: -16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    top: 16px;
    right: 16px;
  }
`;

const ContentWrapper = styled.div`
  position: ${(props) => (props.isPositionDefault ? 'static' : 'relative')};
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: inherit;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    height: ${(props) => (props.isMobileFullScreen ? '100%' : 'auto')};
        overflow: initial;
  }
`;

const ControlsWrapper = styled.div`
  position: absolute;
  bottom: 97px;
  right: 16px;
  display: flex;
  justify-content: flex-end;

  ${(props) => props.isPositionDefault && css`
    right: 48px;
    bottom: 48px;

    @media (max-width: ${props.theme.breakpoints.lg}) {
      right: 32px;
      bottom: 32px;
    }
  `}

  @media (max-width: ${(props) => props.theme.breakpoints.alg}) {
    bottom: 91px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    bottom: ${({ titleLinesCount, isPositionDefault }) => (isPositionDefault ? '32px' : `${((titleLinesCount - 1) * 36) + 95}px`)};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mb}) {
    width: 100%;
    bottom: ${(props) => (props.isPositionDefault ? '32px' : '-72px')};
    right: 0;
    justify-content: center;
  }
`;

const Control = styled.button`
  border: 0;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  outline: none;
  background-repeat: no-repeat;
  font-size: 16px;
  opacity: 1;

  ${(props) => props.arrowLeft && css`
    background-image: url(${leftArrow});
    background-position: center left;
    padding-left: 45px;
  `}

  ${(props) => props.arrowRight && css`
    background-image: url(${rightArrow});
    background-position: center right;
    padding-right: 45px;
  `}
  ${(props) => props.hideBtn && css`
    opacity: 0;
    z-index: -100;
  `}
`;

const IframeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: initial;

  & iframe {
    border: none;
  }
`;

export {
  Wrapper, CloseWrapper, ContentWrapper, ControlsWrapper, Control, IframeWrapper,
};
