import React, { useCallback, useState } from 'react';
import { MapPointShape } from 'components/GoogleMaps/GoogleMaps.shapes';
import PropTypes from 'prop-types';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import MapMarkerFactory from '../MapMarkerFactory';

export const ClusteredMarker = ({
  markerData,
  onClick,
  setMarkerRef,
  hideTooltipIfUnchecked,
  checkedInvestments,
  isOldMarkerStyle,
  isModalOpen,
  activeMarkedEntityData,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = useCallback(
    ({
      id, slug, type, route, externalURL, name,
    }) => onClick({
      id,
      slug,
      type,
      route,
      externalURL,
      name,
      markerData,
    }),
    [onClick, markerData],
  );

  const handleHoverIn = useCallback(() => {
    if (!isHovered) {
      setIsHovered(true);
    }
  }, [markerData.slug]);

  const handleHoverOut = useCallback(() => {
    setIsHovered(false);
  }, [markerData.slug]);

  const ref = useCallback(
    (marker) => { setMarkerRef(marker, markerData.name); },
    [setMarkerRef, markerData.name],
  );


  return (
    <div
      onMouseEnter={handleHoverIn}
      onMouseLeave={handleHoverOut}
      onFocus={() => {}}
      onBlur={() => {}}
    >
      <AdvancedMarker
        zIndex={isHovered ? 5 : 3}
        position={markerData.position}
        ref={ref}
        onClick={() => handleClick({
          id: markerData.id,
          slug: markerData.slug,
          type: markerData.type,
          route: markerData.route,
          externalURL: markerData.externalURL,
          name: markerData.name,
          markerData: markerData.entityData ? markerData.entityData : null,
        })}
      >
        <MapMarkerFactory
          lat={markerData.lat}
          lng={markerData.lng}
          name={markerData.title || markerData.name}
          key={markerData.slug}
          pointRef={ref}
          route={markerData.route}
          type={markerData.type}
          externalURL={markerData.externalURL}
          disabled={markerData.disabled}
          highlighted={markerData.highlighted}
          investmentId={markerData.id}
          investmentSlug={markerData.slug}
          isChecked={checkedInvestments.includes(markerData.id)}
          hideTooltipIfUnchecked={hideTooltipIfUnchecked}
          isOldMarkerStyle={isOldMarkerStyle}
          isTooltipOnHover={markerData.isTooltipOnHover}
          isModalOpen={isModalOpen}
          activeMarkedEntityData={activeMarkedEntityData}
        />
      </AdvancedMarker>
    </div>
  );
};

ClusteredMarker.propTypes = {
  markerData: MapPointShape.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  activeMarkedEntityData: PropTypes.object,
  checkedInvestments: PropTypes.arrayOf(PropTypes.string),
  hideTooltipIfUnchecked: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  isOldMarkerStyle: PropTypes.bool,
  setMarkerRef: PropTypes.func,
  onClick: PropTypes.func,
};

ClusteredMarker.defaultProps = {
  onClick: () => {},
  setMarkerRef: () => {},
  hideTooltipIfUnchecked: false,
  checkedInvestments: [],
  isOldMarkerStyle: false,
  isModalOpen: false,
  activeMarkedEntityData: null,
};
