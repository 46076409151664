import styled from 'styled-components';


const Button = styled.button`
  cursor: pointer;
  border: 0;
  opacity: 0.9;
  background-color: ${(props) => props.theme.secondary14Color};
  color: ${(props) => props.theme.primary2Color};
  font-weight: 700;
  transition: background-color ${(props) => props.theme.animationDuration};
  padding: 20px 18px 18px 20px;

  svg {
    width: 9px;
    height: auto;
  }

  transform: rotate(270deg);

  &:focus {
    outline: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 9px 10px 7px 13px;
    background-color: ${(props) => props.theme.secondary15Color};
    opacity: 1;

    svg path {
      fill: ${(props) => props.theme.secondary12Color};
    }
  }
`;

export { Button };
