import React from 'react';
import PropTypes from 'prop-types';
import FullArrow from 'components/icons/FullArrow';
import PhoneEmptyIcon from 'components/icons/PhoneEmpty';
import FooterCopyright from 'components/FooterCopyright/FooterCopyright';
import { ContactPersonShape } from 'components/content/SalesDepartmentContacts/components/SalesDepartment.shapes';

import { SCROLLABLE_ELEMENT_CLASS_NAME } from 'utils/bodyScrollLock';
import Contact from 'components/content/SalesDepartmentContacts/components/ContactList/Contact/Contact';
import { ContactItemsShape } from './ContactDetails.shapes';
import {
  Wrapper,
  AddressContainer,
  Container,
  Row,
  Text,
  TextContainer,
  PhoneNumberButton,
  AddressDescription,
  ContactPeopleContainer,
  FooterContainer,
  PhoneButton,
  RelativeContainer,
} from './ContactDetails.styles';

const ContactDetails = ({
  onPhoneIconClick, opened, contactItems, contactPeople,
}) => (
  <React.Fragment>
    <PhoneButton onClick={onPhoneIconClick} active={opened}>
      <PhoneEmptyIcon />
    </PhoneButton>
    <Wrapper visible={opened}>
      <RelativeContainer className={SCROLLABLE_ELEMENT_CLASS_NAME}>
        <AddressContainer>
          {
          contactItems.filter((elem) => elem.phone).map((elem, key) => (
            <Container key={key}>
              <Row>
                <TextContainer>
                  <Text> <FullArrow width="6px" height="100%" />{elem.address}
                  </Text>
                  <AddressDescription>
                    {elem.description}
                  </AddressDescription>
                </TextContainer>
              </Row>
              <PhoneNumberButton href={`tel:${elem.phone}`}>
                <PhoneEmptyIcon width="16px" height="100%" />
                {elem.phone}
              </PhoneNumberButton>
            </Container>
          ))
        }
        </AddressContainer>
        <ContactPeopleContainer>
          {
          contactPeople.map((contactPerson, key) => (
            <Contact {...contactPerson} key={key} />
          ))
        }
        </ContactPeopleContainer>
        <FooterContainer>
          <FooterCopyright />
        </FooterContainer>
      </RelativeContainer>
    </Wrapper>
  </React.Fragment>
);

ContactDetails.propTypes = {
  contactItems: PropTypes.arrayOf(ContactItemsShape).isRequired,
  contactPeople: PropTypes.arrayOf(ContactPersonShape).isRequired,
  onPhoneIconClick: PropTypes.func.isRequired,
  opened: PropTypes.bool,
};

ContactDetails.defaultProps = {
  opened: false,
};

export default ContactDetails;
