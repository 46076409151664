import { defineMessages } from 'react-intl';

export default defineMessages({
  navigate: {
    id: 'MapInfoOverlay.navigate',
    defaultMessage: 'Nawiguj',
  },
  goToInvestment: {
    id: 'MapInfoOverlay.goToInvestment',
    defaultMessage: 'Poznaj inwestycję',
  },
});
