import ApiManager from 'utils/ApiManager';
import * as GraphQLTransforms from 'utils/GraphQLTransforms';
import isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';

import configuration from 'config';

import { buildEntity, mergeLocals } from './shared.utils';

export const initialState = {
  loaded: false,
  staticMapUrl: null,
  contactItems: [],
  mapPoints: [],
  menu: [],
  investments: [],
  locals: [],
  clipboardItems: [],
  contactPeople: [],
};

export const actionTypes = {
  LOAD_SHARED_DATA_INIT: 'SHARED/LOAD_SHARED_DATA_INIT',
  LOAD_SHARED_DATA_SUCCESS: 'SHARED/LOAD_SHARED_DATA_SUCCESS',
  LOAD_SHARED_DATA_ERROR: 'SHARED/LOAD_SHARED_DATA_ERROR',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SHARED_DATA_SUCCESS: {
      const nodes = GraphQLTransforms.getNode(action.data[0]);
      const config = GraphQLTransforms.getNode(action.data[1])[0];

      const existingInvestments = GraphQLTransforms.getNode(action.data[2]).filter((el) => el);
      const contactItems = nodes[0].items.map(
        (el) => ({
          ...el.entity,
          type: _get(el.entity, 'type.entity', {}),
        }),
      );
      const investmentMapPoints = [];
      existingInvestments.filter((el) => el.active).forEach((investment) => {
        const filteredInvestments = investment.poi.filter((points) => points.entity.type === 'Investment');
        if (!isEmpty(filteredInvestments)) {
          investmentMapPoints.push({
            id: investment.id,
            slug: investment.slug,
            type: filteredInvestments[0].entity.type,
            externalURL: investment.externalURL,
            ...filteredInvestments[0].entity.map,
            entityData: {
              id: investment.id,
              slug: investment.slug,
              type: filteredInvestments[0].entity.type,
              investmentSlug: investment.slug,
              investmentTitle: investment.title,
              picture: investment.image.derivative.url,
              externalURL: investment.externalURL,
              ...filteredInvestments[0].entity.map,
            },
          });
        }
      });

      const contactPoints = contactItems
        .filter((item) => (item.map && item.map.lat && item.map.lng))
        .map((el) => ({
          id: el.type.id,
          type: 'Office',
          name: el.type.name,
          route: el.route,
          hideOnInvestmentMap: el.hideOnInvestmentMap,
          hideOnContactMap: el.hideOnContactMap,
          ...el.map,
          entityData: {
            id: el.type.id,
            investmentTitle: el.type.name,
            picture: null,
            type: 'Office',
            ...el.map,
          },
        }));

      const mapPoints = [...contactPoints, ...investmentMapPoints];

      const teaserList = GraphQLTransforms.getNode(action.data[3]);
      const fullList = GraphQLTransforms.getNode(action.data[4]);
      const items = mergeLocals(teaserList, fullList);
      const news = action.data[6].news.entities;
      const blogPosts = action.data[7].blogs.entities;

      const mappedInvestmentsSlugs = {};
      const mappedBuildingsSlugs = {};
      const mappedLocalsSlugs = {};
      const mappedNewsSlugs = {};
      const mappedBlogPostsSlugs = {};

      _forEach(existingInvestments, (el) => {
        mappedInvestmentsSlugs[el.slug] = el.id;
        el.buildings.entities.forEach((building) => {
          mappedBuildingsSlugs[building.slug] = building.id;
        });
      });

      _forEach(items, (el) => {
        mappedLocalsSlugs[el.slug] = {
          id: el.id,
          buildingId: el.investment.entity.id || '',
        };
      });

      _forEach(news, (el) => {
        mappedNewsSlugs[el.slug] = el.id;
      });

      _forEach(blogPosts, (el) => {
        mappedBlogPostsSlugs[el.slug] = el.id;
      });

      return {
        ...state,
        loaded: true,
        contact: {
          phone: config.phone,
          mail: config.mail,
        },
        contactItems,
        mapPoints,
        staticMapUrl: GraphQLTransforms.getNode(action.data[0])[0].staticMapUrl,
        menu: config.fieldConfigMenuHeader.map((el) => ({
          id: el.entity.id,
          children: el.entity.children.map(GraphQLTransforms.link),
        })),
        investments: existingInvestments.map((el) => ({
          ...el,
          buildings: el.buildings.entities.map(((building) => ({
            ...building,
            promotionTextInSearchURL: el.promotionTextInSearchURL,
            hasFiltersOff: !el.hasFiltersOn,
          }))),
          logo: GraphQLTransforms.imageStyle(el.logo),
          image: GraphQLTransforms.imageStyle(el.image),
        })),
        items: items.map(buildEntity).map(((local) => ({ ...local, checkURL: local.checkURL }))),
        contactPeople: GraphQLTransforms.getNode(action.data[5]).map((element) => (
          {
            ...element,
            image: GraphQLTransforms.imageStyle(element.image),
            alt: GraphQLTransforms.imageStyle(element.alt),
          }
        )),
        mappedInvestmentsSlugs,
        mappedBuildingsSlugs,
        mappedLocalsSlugs,
        mappedNewsSlugs,
        mappedBlogPostsSlugs,
      };
    }
    default:
      return state;
  }
};

export const loadSharedData = () => (dispatch) => {
  const actions = {
    onInit: actionTypes.LOAD_SHARED_DATA_INIT,
    onSuccess: actionTypes.LOAD_SHARED_DATA_SUCCESS,
    onError: actionTypes.LOAD_SHARED_DATA_ERROR,
  };

  const requests = [];

  requests.push({
    type: 'graphql',
    query: `
      {
        nodeQuery(filter: {conditions: [{field: "type", value: ["contact"]}]}) {
          entities {
            ... on NodeContact {
              staticMapUrl: fieldContactMapImage {
                url,
              },
              items: fieldContactAddresses {
                entity {
                  ... on ParagraphAddresses {
                    address: fieldContactPAddress,
                    description: fieldContactPDescription,
                    phone: fieldContactPPhone,
                    open: fieldContactPOpen,
                    email: fieldContactPEmail,
                    route: fieldContactPGoogleRoute,
                    otherDatesHidden: fieldContactPHideOtherTerms,
                    hideOnInvestmentMap: fieldPHideOnInvestmentMap,
                    hideOnContactMap: fieldPHideOnContactMap,
                    type: fieldContactPType {
                      entity {
                        id: tid
                        name
                      }
                    },
                    map: fieldContactPMap {
                      lat,
                      lng: lon,
                    },
                    isCovidInfoHidden: fieldContactPHideCovidInfo
                  }
                }
              },
            },
          }
        }
      }
    `,
  });

  requests.push({
    type: 'graphql',
    query: `
      {
        nodeQuery(filter: {conditions: [{field: "type", value: ["konfiguracja"]}]}) {
          entities {
            ... on NodeKonfiguracja {
              phone: fieldConfigPhone,
              mail: fieldConfigMail,
              fieldConfigMenuHeader {
                entity {
                  ... on ParagraphMenu {
                    id: fieldMenuId,
                    children: fieldMenuLink {
                      entity { 
                        ... on ParagraphLink {
                          value: fieldPLinkTitle,
                          route: fieldPLinkRoute,
                          params: fieldPLinkParams {
                            name: first,
                            value: second,
                          },
                          href: fieldPLinkAbsolute,
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  });

  requests.push({
    type: 'graphql',
    query: `
      {
        nodeQuery(
          limit: 100,
          filter: {
            conditions: [
              {field: "type", value: ["investment"]},
              {
                field: "nid", 
                value: [
                  "${configuration.investmentIds.businessPremises}",
                  "${configuration.investmentIds.promotions}",
                ], 
                operator: NOT_IN
              }
            ]
          },
          sort: [
            {field: "field_investment_order", direction: ASC}
          ]
        ) {
          entities {
            ... on NodeInvestment {
              id: nid,
              slug: fieldInvestmentSlug,
              title,
              fieldInvestmentUpcoming,
              slug: fieldInvestmentSlug
              fieldSwapVisualisationPlace,
              titleSuffix: fieldInvestmentTitleSuffix,
              mockUrl: fieldInvestmentMockUrl,
              active: fieldInvestmentActive,
              activeGraphicSearch: fieldInvestmentGsActive,
              externalURL: fieldInvestmentExternalUrl,
              promotionTextInSearchURL: fieldInvestmentExternalUrl,
              hasFiltersOn: fieldInvestmentUseFilters,
              logo: fieldInvestmentLogo {
                derivative(style: MEDIUM) {
                  url,
                }
              }
              newLook: fieldInvestmentNewLook
              buildings: reverseFieldBuildingInvestmentNode {
                entities {
                  # TODO: check if the NodeBuilding is correct type
                  ... on  NodeBuilding {
                    id: nid,
                    title,
                    slug: fieldBuildingSlug
                    isBuildingPromotedInSearch: fieldBuildingIsPromoted
                  }
                }
              },
              image: fieldInvestmentBreadcrumbImg {
                derivative(style: ORIGINAL) {
                  url,
                }
              }
              poi: fieldInvestmentPoi {
                entity {
                  ... on  ParagraphPoi {  
                    type: fieldPoiType,
                    map: fieldPoiMap {
                      name,
                      lat,
                      lng: lon
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  });

  requests.push({
    type: 'graphql',
    query: `
      {
        nodeQuery(limit: 1, filter: {conditions: [
          {field: "status", value: ["1"]},
          {field: "field_local_building.entity.status", value: ["1"]},
          {field: "field_local_building.entity.field_building_investment.entity.field_investment_active", value: ["1"]},
        ]}) {
          entities {
            ... on NodeLocal {
              id: nid,
              title,
              status: fieldLocalStatus {
                entity {
                  id: tid,
                  name
                }
              },
              type: fieldLocalType {
                entity {
                  id: tid,
                  name
                }
              },
              localNumber: fieldLocalNumber,
              floor: fieldLocalFloor,
              rooms: fieldLocalRooms,
              investment: fieldLocalBuilding {
                entity {
                  ... on NodeBuilding {
                    id: nid,
                    buildingNumber: fieldBuildingNumber,
                    fieldBuildingFinishDate {
                      value,
                    },
                    fieldBuildingInvestment {
                      entity {
                        id: nid,
                        name: title,
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  });

  requests.push({
    type: 'graphql',
    query: `
      {
        nodeQuery(limit: 10000, filter: {conditions: [
          {field: "type", value: ["local"]},
          {field: "status", value: ["1"]},
          {field: "field_local_hide_sale", value: ["0"]},
          {field: "field_local_building.entity.status", value: ["1"]},
          {field: "field_local_building.entity.field_building_investment.entity.field_investment_active", value: ["1"]},
          {field: "field_local_building.entity.field_building_hide_the_sale", value: ["0"]},
          {field: "field_local_status.entity.tid", value: ["1", "3", "4", "5"]},
        ]}) {
          entities {
            ... on NodeLocal {
              id: nid,
              title,
              slug: fieldLocalSlug
              surface: fieldLocalSurface,
              price: fieldLocalTotalPrice,
              priceNetto: fieldLocalTotalPriceNetto,
              priceM2: fieldLocalPriceM2,
              pricePromo: fieldLocalTotalPricePromo,
              priceM2Promo: fieldLocalPriceM2Promo,
              promoLowestPrice: fieldLowestPromoPrice,
              promoType: fieldLocalPromoType,
              promoFromDate: fieldLocalPromoTo { 
                value
              }
              promoToDate: fieldLocalPromoFrom {
                value
              }
              status: fieldLocalStatus {
                entity {
                  id: tid,
                  name
                }
              },
              type: fieldLocalType {
                entity {
                  id: tid,
                  name
                }
              },
              visualisationUrl: fieldLocal3d {
                derivative(style: MAKIETA3D) {
                  url,
                }
              }
              direction: fieldLocalWorldDirection {
                entity {
                  id: tid,
                  name
                }
              },
              localNumber: fieldLocalNumber,
              imageUrl: fieldLocal2d {
                entity {
                  url
                }
              },
              checkURL: fieldLocalCustomLink,
              isLocalCommercial: fieldIsCommercialLocal,
              roomsList: fieldLocalRoomsList {
                entity {
                  ... on ParagraphRoom {
                    id,
                    name: fieldRoomName {
                      value
                    },
                    surface: fieldRoomSurface,
                  }
                }
              },
              rooms: fieldLocalRooms,
              floor: fieldLocalFloor,
              features: fieldLocalFeatures {
                entity {
                  ... on TaxonomyTermLocalFeatures {
                    id: tid,
                    name,
                    img: fieldLocalFeatureIcon {
                      entity {
                        url
                      },
                    },
                  }
                }
              },
              investment: fieldLocalBuilding {
                entity {
                  ... on NodeBuilding {
                    id: nid,
                    buildingNumber: fieldBuildingNumber,
                    fieldBuildingFinishDate {
                      value,
                    },
                    slug: fieldBuildingSlug
                    fieldBuildingInvestment {
                      entity {
                        ... on NodeInvestment {
                          newLook: fieldInvestmentNewLook
                          slug: fieldInvestmentSlug
                          hasFiltersOn: fieldInvestmentUseFilters
                          published: entityPublished
                        }
                        id: nid,
                        name: title,
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  });

  requests.push({
    type: 'graphql',
    query: `
      {
        nodeQuery(
          filter: {
            conditions: [
              { field: "status", value: ["1"] }
              { operator: EQUAL, field: "type", value: ["contact_person"] }
            ]
          }
        ) {
        entities {
          entityLabel
          ... on NodeContactPerson {
            image: fieldContactPersonImage {
              alt,
              derivative(style: CONTACTPERSON) {
                  url
                }
              }
              email: fieldContactPersonEmail,
              name: fieldContactPersonName,
              phone: fieldContactPersonPhone,
              position: fieldContactPersonPosition,      
            }
          }
        }
      }
    `,
  });

  requests.push({
    type: 'graphql',
    query: `
      {
        slides: nodeQuery(
          filter: {
            conditions: [
              {field: "type", value: ["slide"]},
              {field: "status", value: ["1"]},
              {field: "field_slide_type", value: ["news"]},
            ]
          },
        ) {
          entities {
            ... on NodeSlide {
              imageUrl: fieldSlideImage {
                derivative(style: MAINPAGESLIDER) {
                  url,
                }
              },
              title: fieldSlideTitle,
              description: fieldSlideDescription
            }
          }
        }
        news: nodeQuery(limit:1000, filter: {conditions: [
            {field: "type", value: ["news"]},
            {field: "status", value: ["1"]}
        ]}, sort: [{
          field: "created"
          direction: DESC
        }]) {
          entities {
            ... on NodeNews {
              id: nid,
              slug: fieldNewsSlug,             
            }
          }
        }
      }
    `,
  });

  requests.push({
    type: 'graphql',
    query: `
      {
        blogs: nodeQuery(
        limit: 9999,
          filter: {
            conditions: [
              {field: "type", value: ["blog"]},
              {field: "status", value: ["1"]}
            ]
          }, 
        ) {
          entities {
            ... on NodeBlog {
              id: nid,
              slug: fieldBlogSlug,
            }
          }
        },
      }
    `,
  });

  return ApiManager.request({
    dispatch,
    actions,
    requests,
  });
};
