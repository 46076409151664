const config = {
  version: '2.9.3',
  sliderWidth: {
    value: 1920,
    unit: 'px',
  },
  title: 'Grupa Deweloperska Start - nowe mieszkania Kraków',
  description: 'Nowe mieszkania w Krakowie oferowane przez doświadczonego dewelopera z ponad 40-letnim stażem. Wybierz doskonałą lokalizację w Krakowie i ciesz się komfortem życia.',
  metaOGImage: 'https://api.start.com.pl/sites/default/files/styles/original/public/2023-04/mieszkania_my%C5%9Bliwska_deweloper_START_4.jpg.webp?itok=o6lX0Ed2',
  apiProxyHost: process.env.REACT_APP_API_PROXY_HOST,
  apiHost: process.env.REACT_APP_API_HOST,
  surveys: {
    satisfaction: 'https://docs.google.com/forms/d/e/1FAIpQLSf5FgQOGfqSi0h9M3iGjZJ0T0Lt-3Aip8UhwB8AuahaMwCFlQ/viewform?embedded=true',
    satisfactionHeight: '1800px',
    marketing: 'https://docs.google.com/forms/d/e/1FAIpQLSfbhUDIjMj4MUiq23za9zoIjkwRNgyR2mIcKCkrODt17tFe1Q/viewform?embedded=true',
    marketingHeight: '2051px',
    complaint: 'https://docs.google.com/forms/d/e/1FAIpQLSc7Lwhoe0G7_TdPB5ZD6fmNWVq8qviaCTkVLuKqPqsyW0vDYA/viewform?embedded=true',
    complaintHeight: '1166px',
  },
  defaultLanguage: 'pl',
  menuDisappearTimeout: 150,
  cookie: {
    daysToExpire: 30,
  },
  slider: {
    autoplayInterval: 5000,
  },
  cache: {
    lifetime: 5,
  },
  currencyFormat: {
    style: 'currency',
    currency: 'PLN',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    currencyDisplay: 'symbol',
    useGrouping: 'always',
  },
  cityCenter: {
    lat: 50.06143,
    lng: 19.93658,
  },
  desktopMapZoom: 12,
  mobileMapZoom: 10,
  defaultPoIZoom: 14,
  surfaceFormat: {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
  pagination: {
    newsList: 9,
    promotionsList: 3,
    blog: 9,
    blogPostSeeMore: 3,
    investmentDescription: {
      locals: 18,
    },
  },
  google: {
    mapKey: 'AIzaSyDm_prmRUmFUkGvW5a9BPQD9fuuZB-jpnI',
    analytics: 'GTM-5GR3KGRN',
  },
  youLead: {
    id: process.env.REACT_APP_YOULEAD_ID,
    appId: process.env.REACT_APP_YOULEAD_APP_ID,
    appSecretKey: process.env.REACT_APP_YOULEAD_APP_SECRET_KEY,
  },
  mailPit: {
    enabled: process.env.REACT_APP_MAILPIT_SMTP_CONFIGURATION,
  },
  graphql: {
    url: process.env.REACT_APP_GRAPHQL_URL,
    version: process.env.REACT_APP_GRAPHQL_VERSION,
  },
  locationIds: {
    investmentMap: 'mapa-inwestycji',
    details: 'o-inwestycji',
    gallery: 'galeria',
    search: 'mieszkania',
    localization: 'lokalizacja',
  },
  investmentTypeIds: {
    apartments: 'mieszkanie',
  },
  contact: {
    typeIds: {
      complaintsDepartment: 69,
    },
  },
  search: {
    debounceTime: 500,
    priceInterval: 25000,
    surfaceInterval: 5,
    listEntriesCount: 10,
    types: {
      14: 'flat',
      16: 'studio',
    },
    correctTypes: {
      11: {
        name: 'lokal użytkowy',
      },
      14: {
        name: 'mieszkanie',
      },
    },
    typeIds: {
      // Garages & parking spaces
      garage: 6,
      garageUnderBuilding: 7,
      doubleGarageWithPlatform: 70,
      doubleParkingSpace: 77,
      doubleParkingSpaceWithPlatform: 76,
      doubleParkingSpaceWithPlatformSecond: 74,
      externalParkingSpace: 72,
      motorcycleParkingSpace: 73,
      motorcycleParkingSpaceSecond: 75,
      parkingSpaceInGarage: 13,
      parkingSpaceOnPlatform: 78,
      parkingSpaceOnSinglePlatform: 93,
      parkingSpaceOnDoublePlatform: 92,
      // Cells
      cell: 8,
      cellInBasement: 10,
      cellAffiliate: 64,
      // Others
      businessPremises: 11,
      flat: 14,
      studio: 16,
    },
    directionIds: {
      S: 26,
      SW: 30,
      SE: 29,
      N: 20,
      NE: 24,
      NW: 25,
      W: 31,
      E: 19,
    },
    directions: {
      30: {
        name: 'SW',
      },
      31: {
        name: 'W',
      },
      26: {
        name: 'S',
      },
      29: {
        name: 'SE',
      },
      19: {
        name: 'E',
      },
      24: {
        name: 'NE',
      },
      25: {
        name: 'NW',
      },
      20: {
        name: 'N',
      },
    },
    featureIds: {
      kitchenette: 'aneks kuchenny',
      kitchenetteRoom: 'pokój z aneksem kuchennym',
      kitchenetteHallway: 'przedpokój z aneksem kuchennym',
      kitchenNiche: 'wnęka kuchenna',
      kitchen: 'kuchnia',
      garden: 'ogródek',
      terrace: 'taras',
      terraceGreen: 'taras zielony',
      balcony: 'balkon',
      balconyBuiltUp: 'balkon zabudowany',
      loggia: 'loggia',
      loggiaClosed: 'loggia zamknięta',
      room: 'pokój',
      hallway: 'przedpokój',
      bathroom: 'łazienka',
    },
    statusNames: {
      free: 'wolne',
      prepared: 'przygotowany',
      sold: 'sprzedane',
      reserved: 'zarezerwowane',
      reserved2: 'zarezerwowany',
      selected: 'wybrany',
      promo: 'promocji',
    },
    statusIds: {
      sold: 2,
    },
    statuses: {
      przygotowany: {
        id: 1,
        color: '#7c7c7c',
        opacity: 1,
      },
      sprzedane: {
        id: 2,
        color: 'rgba(61,61,59,0.65)',
        opacity: 1,
      },
      wolne: {
        id: 3,
        color: '#8fae77',
        opacity: 1,
      },
      zarezerwowane: {
        id: 4,
        color: '#fff',
        opacity: 0.5,
      },
      zarezerwowany: {
        id: 5,
        color: '#fff',
        opacity: 0.5,
      },
      promocji: {
        id: 10,
        color: '#b7790c',
        opacity: 1,
      },
      wybrany: {
        id: 10,
        color: '#b70e0c',
        opacity: 1,
      },
      undefined: {
        id: 99,
        color: '#3d3d3b',
      },
    },
  },
  staticPages: {
    legalInformation: {
      id: 1476,
    },
  },
  descriptionOptionIds: {
    list: 1,
    view2D: 2,
    view3D: 3,
    model3D: 4,
  },
  investmentInfoTabIds: {
    visualization: 0,
    surroundings: 1,
    buildings: 2,
  },
  businessPremises: {
    typeNames: {
      forSale: 'na sprzedaż',
      forLease: 'do wynajęcia',
    },
  },
  aggregatedLocalTypes: {
    undefined: -1,
    parking: 1,
    cell: 2,
    flat: 3,
  },
  titleLocalTypes: {
    flat: 'MIESZKANIE',
    parking: 'PARKING',
    cell: 'KOMÓRKA',
  },
  investmentIds: {
    businessPremises: 1198,
    promotions: 1470,
    auraHomeOld: 6372,
    auraHome: 4674,
    wislaneKlimaty: 6810,
    startCity: 6576,
    riverHome: 7033,
  },
  openedModalClassName: 'ReactModal__Body--open',
  floorHighlightedAnimationDuration: 1500,
  investmentObidoNames: {
    3573: 'macedonska',
    3698: 'nowy-horyzont',
    6810: 'wislane-klimaty',
    6576: 'start-city',
    7033: 'river-home',
  },
  obidoWalkBuildingNumbersMap: {
    21: {
      prefix: 'M',
      suffix: '134',
      webService: 'cdn',
      isWithZeroPrefix: true,
    },
    '68c': {
      prefix: '',
      suffix: '132',
      webService: 'cdn',
      isWithoutZeroPrefix: false,
    },
    15: {
      prefix: 'mieszkanie',
      suffix: '',
      webService: 'app',
      isWithoutZeroPrefix: true,
    },
    '15D': {
      prefix: 'a_',
      suffix: 'w',
      webService: 'tours',
      isWithoutZeroPrefix: true,
    },
    '15E': {
      prefix: 'b_',
      suffix: 'w',
      webService: 'tours',
      isWithoutZeroPrefix: true,
    },
    '9a': {
      prefix: 'M',
      suffix: '180',
      webService: 'cdn',
      isWithoutZeroPrefix: false,
    },
  },
  oneColumnImageInvestments: [
    833, 826, 818, 710, 1470, 2416, 829, 1198, 156, 1, 2549, 843, 403, 1471, 840,
  ],
  investmentsWithoutBuildingSelect: [5912, 5986, 3698],
  investmentExternalWindow: {
    6576: 'https://cdn.odysseycrew.com/homerweb/app/?id=134#/building/193',
  },
  investmentWithCustomOfferLink: {
    6991: {
      route: 'wyszukiwarka',
      params: {
        investmentsSelected: 829,
        typeSelected: 11,
        expandFirst: 1,
      },
    },
  },
  investmentsExcludedFromMenu: [6991],
  buildingsWithOdysseyWalks: ['21', '68c', '9a'],
  buildingsWithRemovedPrefix: ['ul. Republiki Korczakowskiej 21'],
  localsWithVisualisationExplicitly: {
    6729: 'https://cdn.odysseycrew.com/homerweb/app/?id=134&ui=headless#/apartment/M87/dollhouse/0',
    6990: 'https://cdn.odysseycrew.com/homerweb/app/?id=134&ui=headless#/apartment/M88/dollhouse/0',
    7646: 'https://cdn.odysseycrew.com/homerweb/app/?id=180&ui=headless#/apartment/U1/dollhouse/0',
  },
  turnkeyFinishesContact: {
    website: 'www.realizacje.com.pl',
    email: 'piotr.korzen@excellent.com.pl',
    firstNumber: '661 953 418',
    secondNumber: '12 653 53 00',
  },
  presentationUrl: 'https://ehomer.pl/pixels/front/9f600c283149b61881ceeaf60fed6d2c/1',
  covidInfoUrl: `${process.env.REACT_APP_API_PROXY_HOST}news/3538`,
  cookiesExpireTime: 365,
  functionalCookies: 'functionalCookies',
  statisticCookies: 'statisticCookies',
  marketingCookies: 'marketingCookies',
  rodoLink: 'https://start.com.pl/prawne',
  campaign: [6734, 6576],
  meta: {
    search: {
      title: 'Wyszukaj mieszkanie',
      description: 'Znajdź idealne mieszkanie dla siebie.',
    },
    turnkeyFinishes: {
      title: 'Wykończenie pod klucz',
      description: 'Z nami wykończysz dodatkowo wnętrze zakupionego mieszkania.',
    },
    aboutUs: {
      title: 'O deweloperze',
      description: 'Ponad 40 lat na rynku. Wiarygodny deweloper.',
    },
    customerOpinions: {
      title: 'Opinie Klientów',
      description: 'Zapoznaj się z opiniami naszych klientów.',
    },
    mediaAboutUs: {
      title: 'Media o nas',
      description: 'Informacje o Nas w przestrzeni medialnej.',
    },
    investments: {
      title: 'Zrealizowane Inwestycje',
      description: 'Zapoznaj sie z naszymi zrealizowanymi inwestycjami.',
    },
    newsList: {
      title: 'Aktualności',
      description: 'Najnowsze informacje. Sprawdź aktualności.',
    },
    blog: {
      title: 'Blog',
      description: 'Porady i ciekawostki.',
    },
    contact: {
      title: 'Kontakt',
      description: 'Nie wiesz jakie mieszkanie wybrać? Napisz lub zadzwoń.',
    },
    buyingAssets: {
      title: 'Grunty',
      description: 'Odkupimy działki, napisz do nas.',
    },
    collaboration: {
      title: 'Współpraca',
      description: 'Kontakt dla podwykonawców.',
    },
    clipboard: {
      title: 'Ulubione',
      description: 'Twoje ulubione mieszkania.',
    },
    legalInformation: {
      title: 'Informacje prawne',
      description: 'Polityka prywatności Grupy START.',
    },
    'wislane-klimaty': {
      title: 'WIŚLANE KLIMATY',
      description: 'Osiedle WIŚLANE KLIMATY - ul. Myśliwska - IV kwartał 2025.',
    },
    'wislane-klimaty-nowy-crm': {
      title: 'WIŚLANE KLIMATY',
      description: 'Osiedle WIŚLANE KLIMATY - ul. Myśliwska - IV kwartał 2025.',
    },
    'start-city': {
      title: 'START CITY',
      description: 'Osiedle START CITY - ul. Republiki korczakowskiej - II kwartał 2025',
    },
    'nowy-horyzont': {
      title: 'NOWY HORYZONT',
      description: 'Osiedle NOWY HORYZONT - os. Na Wzgórzach - gotowe mieszkania.',
    },
    'river-home': {
      title: 'RIVER HOME',
      description: 'Osiedle RIVER HOME - ul. Lipińskiego - IV kwartał 2025',
    },
    servicePremises: {
      title: 'LOKALE USŁUGOWE',
      description: 'Lokale usługowe na wynajem i sprzedaż.',
    },
    servicePremisesMalwowa: {
      title: 'LOKAL USŁUGOWY',
      description: 'Oferta lokalu usługowego przy ul. Malwowej.',
      id: 829,
    },
  },
  defaultBannerSize: '1920 / 650',
  minLoaderTime: 800,
  advancedFiltersScrollTime: 250,
  isFirstLoadTimeout: 500,
  scrollTimeDelay: 200,
  galleryLoadDelay: 250,
  modalTitleDelay: 100,
  odysseyWidths: {
    lg: 1138,
    md: 853,
    sm: 711,
  },
};

module.exports = config;
