import styled from 'styled-components';
import close from './img/closeModal.png';
import closeWhite from './img/closeModalWhite.png';

const StyledButton = styled.button`
  border-radius: 50%;
  border: 1px solid #CCCCCC;
  box-shadow: 0px 2px 4px 0px #0000001A;
  background-color: ${(props) => (props.isMobileFullScreen ? props.theme.red : '#fff')};
  background-image: ${(props) => (props.isMobileFullScreen ? `url(${closeWhite});` : `url(${close});`)};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px;
  color: #000;
  height: 44px;
  max-height: 44px;
  width: 44px;
  max-width: 44px;
  cursor: pointer;
  outline: none;
  font-weight: 700;

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    height: ${(props) => (props.isMobileFullScreen ? '38px' : '32px')};
    width: ${(props) => (props.isMobileFullScreen ? '38px' : '32px')};
  }
`;

export { StyledButton };
