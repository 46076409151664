import PropTypes from 'prop-types';

const PositionShape = PropTypes.shape({
  lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

const MapPointShape = PropTypes.shape({
  lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  disabled: PropTypes.bool,
  highlighted: PropTypes.bool,
  route: PropTypes.string,
  slug: PropTypes.string,
  externalURL: PropTypes.string,
  position: PositionShape,
});

export { MapPointShape };
